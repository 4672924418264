<template>
  <div>
    <!--DEVICES INFO SLIDER-->
    <swiper
      class="swiper-responsive-breakpoints"
      :options="swiperOptions"
      ref="swiper_device"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      @mouseenter.native="stopStartSlider('stop')"
      @mouseleave.native="stopStartSlider('start')"
    >
      <swiper-slide>
        <div class="pb-1">
          <b-row class="match-height">
            <b-col md="12" lg="12">
              <b-card no-body>
                <b-card-body>
                  <div
                    class="home-top d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between"
                  >
                    <b-card-title class="home-title mb-0"
                      >Abatedor
                    </b-card-title>
                    <div
                      class="device-info-wrapper relative d-flex align-items-center justify-content-lg-end"
                    >
                      <p class="serial-number">
                        {{ $t("Serial") }}:
                        <span>{{ device.serial_number }}</span>
                      </p>
                      <p class="model-number">
                        {{ $t("Model") }}:
                        <span>{{ device.model_code }}</span>
                      </p>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="align-items-center">
            <b-col md="12" lg="4">
              <b-card no-body>
                <b-card-body>
                  <div class="home-img">
                    <img src="https://fakeimg.pl/525x251/" alt="" />
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="12" lg="8">
              <b-card no-body class="home-status">
                <b-card-body>
                  <b-row>
                    <b-col class="status-side" xl="4">
                      <b-card-title class="text-status mb-0"
                        >{{ $t("Temperature") }}
                      </b-card-title>
                      <div class="alert-temps icon-status">
                        <div class="alert-temp">
                          <vue-apex-charts
                            type="radialBar"
                            height="300"
                            :options="goalOverviewRadialBar.chartOptions"
                            :series="goalOverviewRadialBar.series"
                          />
                          <div class="temp-range">
                            <span>
                              {{ goal_overview.min_temp }}
                            </span>
                            <span>
                              {{ goal_overview.max_temp }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col class="status-side" lg="4">
                      <b-card-title class="text-status mb-0"
                        >{{ $t("Consumption") }}
                      </b-card-title>
                      <div class="status-icons">
                        <span class="consumo-text-green">-0,02 kWh</span>
                        <div class="icon-status">
                          <span>0,92 kWh</span>
                          <img
                            src="@/assets/images/icons/consumo-green.png"
                            alt=""
                          />
                        </div>
                        <div class="info-btn">
                          <b-button
                            class="btn-sm temp-info-btn"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            v-b-tooltip.hover.left="
                              'Custo e tempo total que o modo de conservação esteve ativo após concluído um ciclo. Para máx. eficiência reduza este valor.'
                            "
                            variant="none"
                          >
                            <feather-icon
                              class="temp-info-icon"
                              icon="AlertCircleIcon"
                            />
                          </b-button>
                        </div>
                      </div>
                      <div class="mt-2 mb-2">
                        <b-card-title class="text-status mb-0"
                          >{{ $t("Active Alerts") }}
                        </b-card-title>
                        <div class="home-alert">
                          <feather-icon class="alert-icon" icon="BellIcon" />
                          <span>{{ $t("No active alerts") }}</span>
                        </div>
                      </div>
                    </b-col>
                    <b-col lg="4" class="status-side-2">
                      <b-card-title class="text-status mb-0"
                        >{{ $t("Device Status") }}
                      </b-card-title>
                      <div class="status-icons">
                        <div class="icon-status">
                          <img src="@/assets/images/icons/ok.png" alt="" />
                          <span>ok</span>
                        </div>
                      </div>
                      <div class="icon-vent-2 mr-0">
                        <b-button
                          class="vent-btn mr-0"
                          variant="none"
                          to="device/1"
                        >
                          <span>{{ $t("More") }}</span>
                        </b-button>
                      </div>
                    </b-col>
                    <div class="info-btn">
                      <b-button
                        class="btn-sm temp-info-btn"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.left="
                          $t(
                            'Total cost and time that the conservation mode was active after completing a cycle. to max. efficiency reduces this value.'
                          )
                        "
                        variant="none"
                      >
                        <feather-icon
                          class="temp-info-icon"
                          icon="AlertCircleIcon"
                        />
                      </b-button>
                    </div>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="pb-1">
          <b-row class="match-height">
            <b-col md="12" lg="12">
              <b-card no-body>
                <b-card-body>
                  <div class="home-top">
                    <b-card-title class="home-title mb-0"
                      >Abatedor e Ultracongelador Copa
                    </b-card-title>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="align-items-center">
            <b-col md="12" lg="4">
              <b-card no-body>
                <b-card-body>
                  <div class="home-img">
                    <img src="https://fakeimg.pl/525x251/" alt="" />
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="12" lg="8">
              <b-card no-body class="home-status">
                <b-card-body>
                  <b-row>
                    <b-col class="status-side" xl="4">
                      <b-card-title class="text-status mb-0"
                        >{{ $t("Temperature") }}
                      </b-card-title>
                      <div class="alert-temps icon-status">
                        <div class="alert-temp">
                          <vue-apex-charts
                            type="radialBar"
                            height="300"
                            :options="goalOverviewRadialBar.chartOptions"
                            :series="goalOverviewRadialBar.series"
                          />
                          <div class="temp-range">
                            <span>
                              {{ goal_overview.min_temp }}
                            </span>
                            <span>
                              {{ goal_overview.max_temp }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col class="status-side" lg="4">
                      <b-card-title class="text-status mb-0"
                        >{{ $t("Consumption") }}
                      </b-card-title>
                      <div class="status-icons">
                        <span class="consumo-text-green">-0,02 kWh</span>
                        <div class="icon-status">
                          <span>0,92 kWh</span>
                          <img
                            src="@/assets/images/icons/consumo-green.png"
                            alt=""
                          />
                        </div>
                        <div class="info-btn">
                          <b-button
                            class="btn-sm temp-info-btn"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            v-b-tooltip.hover.left="
                              'Custo e tempo total que o modo de conservação esteve ativo após concluído um ciclo. Para máx. eficiência reduza este valor.'
                            "
                            variant="none"
                          >
                            <feather-icon
                              class="temp-info-icon"
                              icon="AlertCircleIcon"
                            />
                          </b-button>
                        </div>
                      </div>
                      <div class="mt-2 mb-2">
                        <b-card-title class="text-status mb-0"
                          >{{ $t("Active Alerts") }}
                        </b-card-title>
                        <div class="home-alert">
                          <feather-icon class="alert-icon" icon="BellIcon" />
                          <span>{{ $t("No active alerts") }}</span>
                        </div>
                      </div>
                    </b-col>
                    <b-col lg="4" class="status-side-2">
                      <b-card-title class="text-status mb-0"
                        >{{ $t("Device Status") }}
                      </b-card-title>
                      <div class="status-icons">
                        <div class="icon-status">
                          <img src="@/assets/images/icons/ok.png" alt="" />
                          <span>ok</span>
                        </div>
                      </div>
                      <div class="icon-vent-2 mr-0">
                        <b-button
                          class="vent-btn mr-0"
                          variant="none"
                          to="device/1"
                        >
                          <span>{{ $t("More") }}</span>
                        </b-button>
                      </div>
                    </b-col>
                    <div class="info-btn">
                      <b-button
                        class="btn-sm temp-info-btn"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.left="
                          $t(
                            'Total cost and time that the conservation mode was active after completing a cycle. to max. efficiency reduces this value.'
                          )
                        "
                        variant="none"
                      >
                        <feather-icon
                          class="temp-info-icon"
                          icon="AlertCircleIcon"
                        />
                      </b-button>
                    </div>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="pb-1">
          <b-row class="match-height">
            <b-col md="12" lg="12">
              <b-card no-body>
                <b-card-body>
                  <div class="home-top">
                    <b-card-title class="home-title mb-0"
                      >Abatedor e Ultracongelador Copa
                    </b-card-title>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="align-items-center">
            <b-col md="12" lg="4">
              <b-card no-body>
                <b-card-body>
                  <div class="home-img home-1">
                    <img src="https://fakeimg.pl/525x251/" alt="" />
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="12" lg="8">
              <b-card no-body class="home-status">
                <b-card-body>
                  <b-row>
                    <b-col class="status-side" xl="4">
                      <b-card-title class="text-status mb-0"
                        >{{ $t("Temperature") }}
                      </b-card-title>
                      <div class="alert-temps icon-status">
                        <div class="alert-temp">
                          <vue-apex-charts
                            type="radialBar"
                            height="300"
                            :options="goalOverviewRadialBar.chartOptions"
                            :series="goalOverviewRadialBar.series"
                          />
                          <div class="temp-range">
                            <span>
                              {{ goal_overview.min_temp }}
                            </span>
                            <span>
                              {{ goal_overview.max_temp }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col class="status-side" lg="4">
                      <b-card-title class="text-status mb-0"
                        >{{ $t("Consumption") }}
                      </b-card-title>
                      <div class="status-icons">
                        <span class="consumo-text-green">-0,02 kWh</span>
                        <div class="icon-status">
                          <span>0,92 kWh</span>
                          <img
                            src="@/assets/images/icons/consumo-green.png"
                            alt=""
                          />
                        </div>
                        <div class="info-btn">
                          <b-button
                            class="btn-sm temp-info-btn"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            v-b-tooltip.hover.left="
                              'Custo e tempo total que o modo de conservação esteve ativo após concluído um ciclo. Para máx. eficiência reduza este valor.'
                            "
                            variant="none"
                          >
                            <feather-icon
                              class="temp-info-icon"
                              icon="AlertCircleIcon"
                            />
                          </b-button>
                        </div>
                      </div>
                      <div class="mt-2 mb-2">
                        <b-card-title class="text-status mb-0"
                          >{{ $t("Active Alerts") }}
                        </b-card-title>
                        <div class="home-alert">
                          <feather-icon class="alert-icon" icon="BellIcon" />
                          <span>{{ $t("No active alerts") }}</span>
                        </div>
                      </div>
                    </b-col>
                    <b-col lg="4" class="status-side-2">
                      <b-card-title class="text-status mb-0"
                        >{{ $t("Device Status") }}
                      </b-card-title>
                      <div class="status-icons">
                        <div class="icon-status">
                          <img src="@/assets/images/icons/ok.png" alt="" />
                          <span>ok</span>
                        </div>
                      </div>
                      <div class="icon-vent-2 mr-0">
                        <b-button
                          class="vent-btn mr-0"
                          variant="none"
                          to="device/1"
                        >
                          <span>{{ $t("More") }}</span>
                        </b-button>
                      </div>
                    </b-col>
                    <div class="info-btn">
                      <b-button
                        class="btn-sm temp-info-btn"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.left="
                          $t(
                            'Total cost and time that the conservation mode was active after completing a cycle. to max. efficiency reduces this value.'
                          )
                        "
                        variant="none"
                      >
                        <feather-icon
                          class="temp-info-icon"
                          icon="AlertCircleIcon"
                        />
                      </b-button>
                    </div>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </swiper-slide>
      <div slot="pagination" class="swiper-pagination" />
    </swiper>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  VBTooltip,
} from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Ripple from "vue-ripple-directive";
import VueApexCharts from "vue-apexcharts";
import "swiper/css/swiper.css";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    Swiper,
    SwiperSlide,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  methods: {
    stopStartSlider(action) {
      if (action == "stop") {
        this.swiper.autoplay.stop();
      } else {
        this.swiper.autoplay.start();
      }
    },
  },
  data() {
    return {
      isPauseSlider: false,
      swiperOptions: {
        sliderPerGroup: 1,
        slidesPerView: 1,
        spaceBetween: 50,
        speed: 3000,
        loop: true,
        preventClicks: false,
        preventClicksPropagation: false,
        slideToClickedSlide: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 10000,
          disableOnInteraction: true,
        },
      },
      goal_overview: {
        min_temp: "0,0°C",
        max_temp: "3,0°C",
      },
      goalOverviewRadialBar: {
        series: [50],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ["#fff"],
          plotOptions: {
            radialBar: {
              offsetY: 0,
              startAngle: -90,
              endAngle: 90,
              hollow: {
                size: "50%",
              },
              track: {
                background: "rgba(255, 255, 255, 0.5)",
                strokeWidth: "100%",
              },
              dataLabels: {
                name: {
                  offsetY: 30,
                  color: "#fff",
                  fontSize: "18px",
                },
                value: {
                  offsetY: -15,
                  color: "#fff",
                  fontSize: "29px",
                },
              },
            },
          },
          fill: {
            colors: "#fff",
            opacity: 1,
          },
          stroke: {
            lineCap: "square",
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
          labels: [""],
        },
      },
    };
  },
  computed: {
  },
};
</script>
