<template>
  <b-tabs class="home-tabs">
    <b-tab active>
      <template #title>
        <div class="tab-devices">
          <div class="tab-left">
            <span class="tab-title">{{$t("Devices With Active Alerts")}}</span>
            <span class="info-text">
              <feather-icon icon="AlertCircleIcon" class="info-icon" />
              {{$t("Threshold Exceeded")}}
            </span>
          </div>
          <div class="tab-right">
            <span class="alert-text">
              <feather-icon icon="BellIcon" class="alert-icon" />
              6
            </span>
          </div>
        </div>
      </template>
      <home-info-device-alerts />
    </b-tab>

    <b-tab>
      <template #title>
        <div class="tab-devices">
          <div class="tab-left">
            <span class="tab-title">{{$t("Offline Devices")}}</span>
            <span class="info-text">
              <feather-icon icon="AlertCircleIcon" class="info-icon" />
              {{$t("Threshold Exceeded")}}
            </span>
          </div>
          <div class="tab-right">
            <span class="alert-text">
              <feather-icon icon="CloudOffIcon" class="cloud-icon" />
              6
            </span>
          </div>
        </div>
      </template>
    </b-tab>

    <b-tab>
      <template #title>
        <div class="tab-devices">
          <div class="tab-left">
            <span class="tab-title">{{$t("Total Devices")}}</span>
            <span class="info-text">
              <feather-icon icon="AlertCircleIcon" class="info-icon" />
              {{$t("Threshold Exceeded")}}
            </span>
          </div>
          <div class="tab-right">
            <span class="alert-text">
              <feather-icon icon="ThermometerIcon" class="thermo-icon" />
              {{ totalDevices }}
            </span>
          </div>
        </div>
      </template>
      <home-info-device-total />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import HomeInfoDeviceAlerts from "./HomeInfoDeviceAlerts.vue";
import HomeInfoDeviceTotal from "./HomeInfoDeviceTotal.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    HomeInfoDeviceAlerts,
    HomeInfoDeviceTotal,
  },
  computed: {
    totalDevices() {
      return this.$store.state.devices.devicesList.length;
    },
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
